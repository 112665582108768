<template>
<div class="info-row d-flex flex-column">
  <el-row v-for="(row,index) of rowData" :key="index"
  :class="index%2==0?'row-title':'row-content'">
    <el-col :span="24/row.length" v-for="(item,order) of row"
    :key="order" style="text-align:left;">{{item}}</el-col>
  </el-row>
</div>
</template>
<script>
export default {
  name: 'info-row',
  props: {
    infoList: {
      type: Array,
      default() {
        return [{
          name: 'A相需用电流',
          value: '846A',
        }, {
          name: 'B相需用电流',
          value: '841A',
        }, {
          name: 'C相需用电流',
          value: '874A',
        }];
      },
    },
  },
  data() {
    return {
      rowData: [[], []],
    };
  },
  created() {
    this.rowData = this.getRowData();
  },
  watch: {
    infoList() {
      this.rowData = this.getRowData();
    },
  },
  methods: {
    getRowData() {
      const arr = [[], []];
      if (!this.infoList) {
        return arr;
      }
      for (let i = 0; i < this.infoList.length; i += 1) {
        const info = this.infoList[i];
        arr[0].push(info.name);
        arr[1].push(info.value + info.unit);
      }
      console.log('rowArr', arr);
      return arr;
    },
  },
};
</script>
<style scoped>
.row-title {
  color: #8696BF;
}
.row-content {
  color:#fff;
  background-color: #162165;
}
.row-content,
.row-title{
  padding:4px;
}
</style>
