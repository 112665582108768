<template>
  <div class="backup text-white cursor-pointer d-flex align-items-center" @click="backupListener">
    <img src="../../assets/images/navs/back-arrow.png" class="backup-img">返回
  </div>
</template>
<script>
export default {
  name: 'backup',
  methods: {
    backupListener() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.el-icon-caret-left{
  background-color: #70f5ff;
  border-radius: 50%;
}
.backup{
  padding:0 0.6rem 0.8rem;
}
.backup-img{
  /* height: 1.2rem; */
  margin-right:0.2rem;
}
</style>
