<template>
  <!-- eslint-disable-next-line max-len -->
  <g
    class="db-arrow-up"
    :transform="isActive | getTransform(isDown)"
    v-on:click="$emit('clickEvent')"
  >
    <path transform="scale(0.025)" :stroke="isActive ? '#c9141c' : '#0dc734'"
     :fill="isActive ? '#c9141c' : '#0dc734'"
     d="M231.5 577.4L512 296.9l280.5 280.5c5.9 5.9 13.5 8.8 21.2 8.8s
     15.4-2.9 21.2-8.8c11.7-11.7 11.7-30.7 0-42.4L
     533.2 233.2c-11.7-11.7-30.7-11.7-42.4 0L189 535c
     -11.7 11.7-11.7 30.7 0 42.4 11.8 11.7 30.8 11.7 42.5 0z
     M835 748.3L533.2 446.6c-11.7-11.7-30.7-11.7-42.4 0L189 748.3
     c-11.7 11.7-11.7 30.7 0 42.4s30.7 11.7 42.4 0L512 510.2l
     280.5 280.5c5.9 5.9 13.5 8.8 21.2 8.8s15.4-2.9 21.2-8.8c
     11.8-11.7 11.8-30.7 0.1-42.4z"
    ></path>
  </g>
</template>
<script>
/*eslint-disable*/
//向上双箭头
export default {
  name: "db-arrow-up",
  props: {
    isActive: {
      type: Number,
      default: 0,
    },
    isDown: {
      default: 0,
      type: [Number, Boolean],
    },
  },
  filters: {
    getTransform: function (isActive, isDown) {
      if (isActive) {
        return "translate(0,8)";
      }
      if (isDown) {
        return "translate(0,30),rotate(270)";
      }
      return "translate(26,8),rotate(90)";
    },
  },
};
</script>
