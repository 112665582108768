<template>
  <g
    class="rect-svg"
    v-on:click.stop="emitClick"
    v-on:mouseover.stop="$emit('mouseOver')"
    v-on:mouseout.stop="$emit('mouseOut')"
  >
    <rect
      v-on:click.stop="emitClick"
      :stroke-dasharray="setDashArray"
      :width="rectInfo.width"
      :height="rectInfo.height"
      :stroke="strokeColor"
      :fill="fillColor"
      :stroke-width="rectInfo.isBorder ? 0 : 2"
    ></rect>
    <text
      v-on:click.stop="emitClick"
      v-if="rectText"
      :x="rectInfo.width / 2"
      :y="rectInfo.height / 2 + 5"
      :fill="strokeColor"
      class="text-white"
      text-anchor="middle"
      v-bind:style="{ fontSize: `${textSize}rem` }"
      >{{ rectText }}</text
    >
  </g>
</template>
<script>
export default {
  name: 'rect-svg',
  props: {
    isActive: {
      type: Number,
      default: 0,
    },
    isColor: {
      default: 1,
    },
    textSize: {
      type: Number,
      default: 1,
    },
    rectText: {
      default: '',
    },
    rectInfo: {
      default() {
        return {
          width: 150,
          height: 120,
          isDash: 0,
          isBorder: 0,
        };
      },
      type: Object,
    },
  },
  mounted() {
    this.getFillColor();
    this.getStrokeColor();
  },
  data() {
    return {
      fillColor: '#ff6d6d',
      strokeColor: 'white',
    };
  },
  watch: {
    isActive() {
      this.getFillColor();
      this.getStrokeColor();
    },
  },
  computed: {
    setDashArray() {
      return this.rectInfo.isDash ? '5,5' : '';
    },
  },
  methods: {
    getStrokeColor() {
      if (!this.isColor) {
        this.strokeColor = 'white';
        return;
      }
      this.strokeColor = this.isActive ? '#c9141c' : '#0dc734';
    },
    getFillColor() {
      if (!this.isColor) {
        this.fillColor = 'transparent';
        return;
      }
      this.fillColor = this.isActive ? '#c9141c' : 'transparent';
    },
    getErrorColor() {
      this.fillColor = this.fillColor === '#59c9bd' ? '#ff6d6d' : '#59c9bd';
      setTimeout(this.getErrorColor, 500);
    },
    emitClick() {
      if (this.rectText) {
        this.$emit('clickEvent', this.rectText);
      } else {
        this.$emit('clickEvent');
      }
    },
  },
};
</script>
<style scoped>
.react-svg {
  cursor: pointer;
}
</style>
