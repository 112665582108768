export default {
  data() {
    return {
      routeName: '',
    };
  },
  created() {
    this.routeName = this.$route.name;
  },
  methods: {
    turnAsking({ routeName, nowRouteName, func }, timeout = 1800000) {
      if (routeName !== nowRouteName) {
        return null;
      }
      return setTimeout(func, timeout);
    },
  },
};
