const EQUIPMENT_STATE = [{
  name: '电寿命',
  value: '100',
  unit: '',
}, {
  name: '机械寿命',
  value: '100',
  unit: '',
}, {
  name: '触头磨损',
  value: '0',
  unit: '',
}];
const THREE_PHASE_VOLTAGE = [{
  name: 'A相电压',
  value: '215.6V',
  unit: 'V',
}, {
  name: 'B相电压',
  value: '224.7V',
  unit: 'V',
}, {
  name: 'C相电压',
  value: '218.7V',
  unit: 'V',
}];
const THREE_PHASE_ELECTRICITY = [{
  name: 'A相电流',
  value: '16.78A',
  unit: 'A',
}, {
  name: 'B相电流',
  value: '13.45A',
  unit: 'A',
}, {
  name: 'C相电流',
  value: '17.25A',
  unit: 'A',
}];

const POWER = [{
  name: 'A相有功功率',
  value: '17.45kW',
  unit: 'kW',
}, {
  name: 'B相有功功率',
  value: '16.78kW',
  unit: 'kW',
}, {
  name: 'C相有功功率',
  value: '14.95kW',
  unit: 'kW',
}];
const THREE_PHASE_NEED_ELECTRICITY = [{
  name: 'A相无功功率',
  value: '78.54',
  unit: 'kVar',
}, {
  name: 'B相无功功率',
  value: '105.1',
  unit: 'kVar',
}, {
  name: 'C相无功功率',
  value: '94.56A',
  unit: 'kVar',
}];
const NEED_POWER = [{
  name: 'A相功率因数',
  value: '451kW',
  unit: '',
}, {
  name: 'B相功率因数',
  value: '123.4kVar',
  unit: '',
}, {
  name: 'C相功率因数',
  value: '79.12kVar',
  unit: '',
}];
const WARN_STATE = [{
  name: '欠压报警',
  value: 0,
}, {
  name: '欠频报警',
  value: 0,
}, {
  name: '相序报警',
  value: 0,
}, {
  name: '自诊断报警',
  value: 0,
}];
const WARN_STATE_EXT = [{
  name: '过压报警',
  value: 0,
}, {
  name: '过频报警',
  value: 0,
}, {
  name: '逆功率报警',
  value: 0,
}, {
  name: '通讯超时',
  value: 0,
}];
export {
  EQUIPMENT_STATE,
  THREE_PHASE_VOLTAGE,
  THREE_PHASE_ELECTRICITY,
  POWER,
  THREE_PHASE_NEED_ELECTRICITY,
  NEED_POWER,
  WARN_STATE,
  WARN_STATE_EXT,
};
