<template>
  <g class="arrow-box">
    <rect
      fill="transparent"
      width="26"
      height="100"
      v-on:click="$emit('clickEvent')"
    ></rect>
    <arrow-switch
      :isActive="status.arrow"
      v-on:clickEvent="$emit('clickEvent')"
      :isDown="parseInt(0)"
    ></arrow-switch>
    <rect-svg
      transform="translate(8,40)"
      v-on:clickEvent="$emit('clickEvent')"
      :isActive="status.box"
      :rectInfo="rectInfo"
    ></rect-svg>
    <arrow-switch
      :isActive="status.arrow"
      v-on:clickEvent="$emit('clickEvent')"
      :isDown="parseInt(1)"
    ></arrow-switch>
    <text-list
      v-if="showText"
      :showPropertyType="showPropertyType"
      :textSize="textSize"
      :transform="`translate(30,${showPropertyType == 3 ? 40 : 0})`"
      :propertyArr="propertyArr"
      v-on:propClick="propClickListener"
    ></text-list>
  </g>
</template>
<script>
import textList from '@/components/court-circuit-diagram/svgs/utils/text-list.vue';
import arrowSwitch from '@/components/court-circuit-diagram/svgs/utils/arrow-switch.vue';
import rectSvg from '@/components/court-circuit-diagram/svgs/utils/rect.vue';

export default {
  name: 'arrow-box',
  components: {
    arrowSwitch,
    rectSvg,
    textList,
  },
  props: {
    showText: {
      type: Number,
      default: 1,
    },
    showPropertyType: {
      type: Number,
      default: 0,
    },
    status: {
      default() {
        return {
          arrow: 1,
          box: 1,
        };
      },
      type: Object,
    },
    propertyArr: {
      type: Array,
      default() {
        return [];
      },
    },
    textSize: {
      type: Number,
      default: 0.8,
    },
  },
  data() {
    return {
      rectInfo: {
        width: 10,
        height: 20,
      },
    };
  },
  filters: {
    getTransform() {
      return 'translate(40,60),rotate(180)';
    },
  },
  methods: {
    propClickListener(propItem) {
      this.$emit('propClick', propItem);
    },
  },
};
</script>
