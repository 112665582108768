<template>
  <div class="section-frame d-flex flex-column">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'section-frame',
  data() {
    return {};
  },
  methods: {
  },
};
</script>
<style scoped>
.section-frame{
  background: linear-gradient(15deg, #1c4596, #181d62);
  /* background: linear-gradient(150deg, #1c4596, #181d62); */
  border: 2px solid #1b71cc;
  /* padding: 1rem 0.8rem; */
  padding: 1.5rem 1rem 1rem 2rem;
}
</style>
