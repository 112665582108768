//出线开关
<template>
  <g class="outline-switch">
    <arrow-box
      transform="scale(1.5)"
      @propClick="propClickListener"
      :status="status"
      :textSize="textSize"
      :propertyArr="propertyArr"
      :showPropertyType="showPropertyType"
      v-on:clickEvent="getClickEvent"
    ></arrow-box>
    <line x1="19" x2="19" y1="150" y2="260" stroke="white"
     stroke-width="4"></line>
    <triangle transform="translate(9,260),scale(0.3)"></triangle>
  </g>
</template>
<script>
// 出线开关
import arrowBox from '@/components/court-circuit-diagram/svgs/utils/arrow-box.vue';
import triangle from '@/components/court-circuit-diagram/svgs/utils/triangle.vue';

export default {
  name: 'outline-switch',
  components: {
    arrowBox,
    triangle,
  },
  props: {
    showPropertyType: {
      type: Number,
      default: 0,
    },
    status: {
      type: Object,
      default() {
        return {
          arrow: 1,
          box: 1,
        };
      },
    },
    propertyArr: {
      type: Array,
      default() {
        return [
        ];
      },
    },
    textSize: {
      type: Number,
    },
  },
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
  },
  methods: {
    getClickEvent() {
      this.$emit('showDia');
    },
    propClickListener(propItem) {
      this.$emit('propClick', propItem);
    },
  },
};
</script>
<style scoped>
</style>
