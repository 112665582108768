//配电室一次图-二变压器
<template>
  <!-- 总 -->
  <svg
    :class="{ 'small-svg': primaryType }"
    class="room-whole-primary-diagram"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="-50,0,2580,1000"
    v-if="courtsInfo.TaiQv"
  >
    <line x1="200" x2="1000" y1="40" y2="40" stroke="white" stroke-width="6" />
    <line v-if="courtsInfo.LianLuoGui.length>0"
       x1="1500" x2="2300" y1="40" y2="40" stroke="white" stroke-width="6" />
    <!-- 变压器1# -->
    <g transform="translate(545,0)">
      <line x1="20" x2="20" y1="40" y2="90" stroke="white" stroke-width="2" />
      <line x1="19" x2="19" y1="150" y2="200" stroke="white" stroke-width="2" />
      <transformer
        v-on:transClick="$emit('transClick', courtsInfo.TaiQv[0])"
        class="cursor-pointer"
        transform="translate(0,90)"
      />
      <text transform="translate(50,140)" class="transformer-name" fill="white">
        {{ courtsInfo.TaiQv[0].BYQ[0].SBMName }}
      </text>
      <text-list
        :textSize="parseFloat(1.0)"
        :showPropertyType="parseInt(2)"
        :propertyArr="
          courtsInfo.TaiQv[0].BYQ[0] | filterProperties(equipIdPropMap)
        "
        transform="translate(-80,120)"
      />
    </g>
    <!-- 变压器2# -->
    <g transform="translate(1844,0)" v-if="courtsInfo.TaiQv[1]">
      <line x1="20" x2="20" y1="40" y2="90" stroke="white" stroke-width="2" />
      <line x1="20" x2="20" y1="150" y2="200" stroke="white" stroke-width="2" />
      <transformer
        v-on:transClick="$emit('transClick', courtsInfo.TaiQv[1])"
        class="cursor-pointer"
        transform="translate(0,90)"
      />
      <text transform="translate(60,140)" class="transformer-name" fill="white">
        {{ courtsInfo.TaiQv[1].BYQ[0].SBMName }}
      </text>
      <text-list
        :textSize="parseFloat(1.0)"
        :showPropertyType="parseInt(2)"
        :propertyArr="
          courtsInfo.TaiQv[1].BYQ[0] | filterProperties(equipIdPropMap)
        "
        transform="translate(-80,120)"
      />
    </g>
    <!-- 变压器1#进线 -->
    <g transform="translate(52,220)">
      <arrow-switch
        class="cursor-pointer"
        :showPropertyType="primaryType ? 3 : 0"
        :propertyArr="
          courtsInfo.TaiQv[0].JX[0] | filterProperties(equipIdPropMap)
        "
        :status="courtsInfo.TaiQv[0].JX[0] | filterStatus"
        transform="translate(8,120),scale(1.0)"
        @clickEvent="
          showSwitchControl(courtsInfo.TaiQv[0].JX[0], primaryType ? 3 : 0)
        "
        @propClick="propClickListener(arguments, courtsInfo.TaiQv[0].JX[0])"
      />
      <path
        d="M512,-22 L512,20 L-80,20  L-80,240 L20,240 L20,220 M20,120 L20,80"
        stroke="white"
        fill="none"
        stroke-width="2"
      />
      <text
        class="switch-name cursor-pointer"
        transform="translate(-50,130),scale(0.8)"
        fill="white"
        v-html="filterName(`${courtsInfo.TaiQv[0].JX[0].SBMName}`)"
      />
    </g>
    <!-- 变压器2#进线 -->
    <g transform="translate(1664,220)" v-if="courtsInfo.TaiQv[1]">
      <arrow-switch
        class="cursor-pointer"
        :showPropertyType="primaryType ? 3 : 0"
        :propertyArr="
          courtsInfo.TaiQv[1].JX[0] | filterProperties(equipIdPropMap)
        "
        :status="courtsInfo.TaiQv[1].JX[0] | filterStatus"
        transform="translate(748,480),scale(1.0)"
        @clickEvent="
          showSwitchControl(courtsInfo.TaiQv[1].JX[0], primaryType ? 3 : 0)
        "
        @propClick="propClickListener(arguments, courtsInfo.TaiQv[1].JX[0])"
      />
      <path
        d="M200,-22 L200,20 L840,20  L840,690 L760,690 L760,580 M760,480 L760,440"
        stroke="white"
        fill="none"
        stroke-width="2"
      />
      <text
        class="switch-name cursor-pointer"
        transform="translate(770,720),scale(0.8)"
        fill="white"
        v-html="filterName(`${courtsInfo.TaiQv[1].JX[0].SBMName}`)"
      />
    </g>
    <g transform="translate(0,300)">
      <text fill="white" transform="translate(0,-25)">Ⅰ母</text>
      <line x1="0" x2="2460" stroke="white" stroke-width="4" />
    </g>
    <!-- 联络柜 -->
    <g transform="translate(2418,390)" v-if="courtsInfo.LianLuoGui.length>0">
      <arrow-switch
        class="cursor-pointer"
        :status="{ arrow: 0, box: 0 }"
      ></arrow-switch>
      <path
        d="M12,-90 L12,0 M12,100  L12,270 Z"
        stroke="white"
        fill="none"
        stroke-width="2"
      ></path>
    </g>
    <!-- 出线 -->
    <g transform="translate(0,660)" v-if="courtsInfo.TaiQv[1]">
      <text fill="white" transform="translate(0,-25)">Ⅱ母</text>
      <line x1="-10" x2="2460" stroke="white" stroke-width="4" />
    </g>
    <g transform="translate(150,300)">
      <g
        v-for="(out, index) in courtsInfo.TaiQv[0].CX"
        :key="'outline-' + index"
        :transform="`translate(${
          getOutSpace(courtsInfo.TaiQv[0].CX.length, index) + 0
        },0),scale(0.6)`"
      >
        <line x1="20" x2="20" y1="0" y2="60" stroke="white" stroke-width="4" />
        <outline-switch
          v-on:showDia="getShowAllProps(out, primaryType ? 3 : 0)"
          class="cursor-pointer"
          transform="translate(0,60)"
          :showPropertyType="primaryType ? 3 : 0"
          :propertyArr="out | filterProperties(equipIdPropMap)"
          :status="out | filterStatus"
          :textSize="courtsInfo.TaiQv[0].CX.length | filterTextSize"
          @propClick="propClickListener(arguments, out)"
        />
        <text
          class="switch-name cursor-pointer"
          v-on:click.stop="getShowDia(out)"
          transform="translate(0,380)"
          fill="white"
          v-html="filterName(`${out.SBMName}`)"
        />
      </g>
    </g>
    <g transform="translate(160,660)" v-if="courtsInfo.TaiQv[1]">
      <g
        v-for="(out, index) in courtsInfo.TaiQv[1].CX"
        :key="'outline-' + index"
        :transform="`translate(${
          getOut2Space(courtsInfo.TaiQv[1].CX.length, index) - 100
        },0),scale(0.6)`"
      >
        <line x1="20" x2="20" y1="0" y2="60" stroke="white" stroke-width="4" />
        <outline-switch
          v-on:showDia="getShowAllProps(out, primaryType ? 3 : 0)"
          class="cursor-pointer"
          transform="translate(0,60)"
          :showPropertyType="primaryType ? 3 : 0"
          :propertyArr="out | filterProperties(equipIdPropMap)"
          :textSize="courtsInfo.TaiQv[1].CX.length | filterTextSize"
          :status="out | filterStatus"
          @propClick="propClickListener(arguments, out)"
        />
        <text
          class="switch-name cursor-pointer"
          v-on:click.stop="getShowDia(out)"
          transform="translate(0,380)"
          fill="white"
          v-html="filterName(`${out.SBMName}`)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
import arrowSwitch from '@/components/court-circuit-diagram/svgs/utils/arrow-box.vue';
import outlineSwitch from '@/components/court-circuit-diagram/svgs/utils/outline-switch.vue';
import transformer from '@/components/court-circuit-diagram/svgs/utils/transformer.vue';
import textList from '@/components/court-circuit-diagram/svgs/utils/text-list.vue';

export default {
  name: 'room-whole-primary-diagram',
  components: {
    arrowSwitch,
    outlineSwitch,
    transformer,
    textList,
  },
  props: {
    equipIdPropArray: {
      type: Array,
      default() {
        return [];
      },
    },
    courtsInfo: {
      type: [Object, String],
      default() {
        return {};
      },
    },
    contactTanks: {
      type: Array,
      default() {
        return [];
      },
    },
    primaryType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      roomName: '',
      selectRoomIds: '',
    };
  },
  mounted() {
  },
  //  变压器
  computed: {
    accountId: {
      get() {
        return this.$store.getter.accountId;
      },
    },
    selectRoom: {
      get() {
        return this.$store.getter.roomInfo;
      },
    },
    selectCourtIds: {
      get() {
        return this.$store.getter.selectCourtIds;
      },
    },
    equipIdPropMap() {
      return new Map(this.equipIdPropArray);
    },
    skinType() {
      return this.$store.getters.skinType;
    },
  },
  filters: {
    filterTextSize(length) {
      if (length > 15) {
        return 0.7;
      }
      return 0.8;
    },
    filterStatus(line) {
      let onNo = 0;
      if (!line) {
        return { arrow: onNo, box: onNo };
      }
      if (line && line.JCX && line.JCX.length > 0) {
        let eleFlows = line.JCX.find((jcx) => jcx.ID === 212);
        if (!eleFlows) {
          const props = line.JCX.filter((prop) => prop.ID > 171 && prop.ID < 175);

          if (props.length > 0) {
            props.sort((a, b) => b.Value - a.Value);
            [eleFlows] = props;
          }
        }
        if (eleFlows) {
          onNo = eleFlows.Value > 0 ? 1 : 0;
        }
      }
      return { arrow: onNo, box: onNo };
    },
    filterProperties(info) {
      return info.JCX;
    },
  },
  watch: {
    selectRoom() {},
  },
  methods: {
    propClickListener(args, info) {
      const propItem = args[0];
      this.$emit('propClick', propItem, info);
    },
    getShowDia(out) {
      this.$emit('showDia', out);
    },
    // 获取所有属性值
    getShowAllProps(out, type) {
      this.$emit('showAllProps', out, type);
    },
    showSwitchControl(out) {
      this.$emit('switchControl', out);
    },
    getOutSpace(length, index) {
      return (2260 / length).toFixed(2) * index;
    },
    getOut2Space(length, index) {
      return (2320 / length).toFixed(2) * index;
    },
    filterName(name) {
      let span = '';
      for (let i = 0, j = 0; i < name.length; i += 4, j += 1) {
        const textSpan = name.slice(i, i + 4);
        span += `<tspan font-size="24" x="0" y="${j * 28}">${textSpan}</tspan>`;
      }
      return span;
    },
  },
};
</script>
<style scoped>
svg:not(:root){
  overflow:scroll;
}
.trans-name {
  font-size: 1.6rem;
  font-weight: bold;
}
.outline-name {
  font-size: 0.8rem;
}
.switch-name {
  font-size: 0.9rem;
}
.transformer-name {
  font-size: 1.4rem;
  font-weight: bold;
}
.high-voltage-name {
  font-size: 1.2rem;
}
.partial-discharge {
  fill: aliceblue;
  font-size: 0.8rem;
}
/*大盒子 */
.Line {
  /* border: 1px solid white; */

  overflow-y: auto;
}
.col-3 {
  flex: 0 0 20%;
}
/* 线损概况 */
.General {
  /* display: none!important; */
  width: 100%;
  height: 86%;
  background: rgba(10, 36, 121, 0.5);
}
/* 线损文字 */
.left-title {
  font-family: "微软雅黑";
  font-size: 1.2rem;
  background-color: #244fc1;
  padding: 0.6rem;
}
/*  */
.one {
  margin-left: 10%;
  width: 5rem;
  height: 5rem;
  background: linear-gradient(135deg, #6c94ed, #0e5cfc);
  background-size: 100% 100%;
  border-radius: 50%;
  margin-right: 10%;
}
.one img {
  width: 95%;
  padding-top: 20%;
  padding-left: 25%;
  /* margin: 0 auto; */
  padding-right: 25%;
}
p {
  padding-bottom: 0.5rem;
  font-size: 0.8rem;
  color: white;
  text-align: left;
}
img {
  padding-right: 2%;
}
ul li p {
  margin: 0;
  padding: 0;
}
ul > li {
  height: 100%;
  padding-bottom: 0.5rem;
  list-style: none;
  border-bottom: 2px dashed rgba(10, 12, 84, 0.8);
}
ul:last-child > li {
  border: none;
}
svg {
  width: 100%;
  height: 100%;
}
svg.small-svg {
  width: 100%;
  height: 85%;
}
</style>
