<template>
  <div class="no-data-temporarily d-flex
  justify-content-center align-items-center dark"
  >
    暂无数据
  </div>
</template>
<script>
export default {
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
  },
};
</script>
<style scoped>
.dark .no-data-temporarily{
  color: #4477DD;
}
.no-data-temporarily{
  font-size: 16px;
  color:#4477DD;
  text-align:center;
  vertical-align:center;
}
</style>
