<template>
  <div
    class="alerting-signal d-flex flex-column justify-content-center align-items-center"
  >
    <div class="img-container">
      <img class="img-fluid"
      :src="signalInfo.isAlerting==1?signalInfo.alertingImg:signalInfo.normalImg" />
    </div>
    <div class="text-white item-value" >{{ signalInfo.title }}</div>
  </div>
</template>
<script>
export default {
  name: 'alerting-signal',
  props: {
    signalInfo: {
      type: Object,
      default() {
        return {
          normalImg: '',
          alertingImg: '',
          isAlerting: 0,
          title: '',
        };
      },
    },
  },
};
</script>
<style scoped>
.img-container{
  width: 3.6rem;
  height: 3.6rem;
}
.img-fluid{
  width: 100%;
}
.item-value{
  margin-top: 0.6rem;
}
</style>
