<template>
  <div v-if="options" ref="charts" class="chart"></div>
  <NoDataTemporarily v-else></NoDataTemporarily>
</template>
<script>
import NoDataTemporarily from '@/components/utils/no-data-temporarily.vue';
import { initResizeListener } from '@/utils/utils';

export default {
  name: 'charts',
  props: {
    chartId: {
      type: String,
      default: 'chart',
    },
    options: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {
    NoDataTemporarily,
  },
  data() {
    return {
      dom: null,
      colors: [],
      charts: '',
      redener: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.charts.dispose();
    this.charts = null;
    this.dom = null;
    next();
  },
  created() {
  },
  mounted() {
    if (this.options) {
      if (!this.charts) {
        this.initChart();
      }
      this.setOptions();
    }
  },
  beforeDestroy() {
    this.dom = null;
    this.resizeMap = null;
    this.initChart = null;
    this.setOptions = null;
  },
  watch: {
    options: {
      deep: true,
      handler() {
        if (!this.options) {
          return;
        }
        console.log(this.options);
        this.$nextTick(() => {
          if (!this.charts) {
            this.initChart();
          }
          this.setOptions();
        });
      },
    },
  },
  methods: {
    initChart() {
      this.charts = this.$echarts.init(this.$refs.charts);
      initResizeListener(this.charts, this.$refs.charts);
    },
    setOptions() {
      if (!this.options) {
        return;
      }
      this.charts.setOption(this.options);
    },
  },
};
</script>
<style scoped>
</style>
