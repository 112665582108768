<template>
  <div
    class="room-primary flex-grow-1 d-flex flex-column
     justify-content-center align-items-center position-relative"
  >
    <template v-if="selectOptionList">
    <div
      v-if="selectOptionList&&selectOptionList.length > 1"
      class="select-option-container position-absolute d-flex
      justify-content-center"
    >
      <select-options-nav
        @selectOption="getSelectOption"
        :selectOption="selectOrder"
        :selectList="selectOptionList"
      ></select-options-nav>
    </div>
    <div class="svg-container none-height flex-grow-1 w-100 d-flex">
      <room-whole-primary-diagram
        :primaryType="primaryType"
        class="primary-diagram-svg"
        style="width:100%;max-width:100%;height:auto;"
        v-if="courtsInfo && courtsInfo.TaiQv "
        @showAllProps="showAllProps"
        :courtsInfo="courtsInfo"
        :equipIdPropArray="subEquipInfoList"
        @propClick="showPropCurve"
        @switchControl="showSwitchControl"
      ></room-whole-primary-diagram>
    </div>
    </template>
    <no-data-temporarily v-else></no-data-temporarily>
    <el-dialog
      diaId="video-dia"
      :visible.sync="showVideo"
      :title="diaTitle_1"
      class="dark"
      :width="(secDiaType==2)?'90%':'50%'"
    >
      <monitoring-curve
        v-if="secDiaType == 0"
        :equipType="equipType"
        :propInfo="selectPropInfo"
        :equipmentInfo="selectEquipment"
        class="flex-grow-1"
        style="min-height:400px;"
      ></monitoring-curve>
      <div v-else-if="secDiaType == 1" class="overflow-y flex-wrap">
        <el-table size="small" stripe  class="dark"
        :data="propertiesData"
        :row-class-name="(...nu)=>{return 'dark text-white'}">
          <el-table-column prop="Name" label="属性名称"></el-table-column>
          <el-table-column prop="Code" label="属性代码"></el-table-column>
          <el-table-column prop="Value" label="属性值"></el-table-column>
          <el-table-column prop="Time" label="记录时间"></el-table-column>
        </el-table>
      </div>
      <SwitchInfo v-else  :equipInfo="selectEquipment"></SwitchInfo>
    </el-dialog>
  </div>
</template>
<script>
import roomWholePrimaryDiagram from '@/components/svgs/room-whole-primary-diagram.vue';
import monitoringCurve from '@/components/general/monitoring-curve.vue';
import selectOptionsNav from '@/components/general/select-options-nav.vue';
import { getDiagramData, getLinelossData } from '@/api/switchroom';
import clone from '@/utils/clone';
import NoDataTemporarily from '@/components/utils/no-data-temporarily.vue';
import TurnAskingMixin from '@/mixins/turn-ask';
import SwitchInfo from '@/components/project-operation-monitor/switch-info/switch-info.vue';

export default {
  name: 'room-primary',
  mixins: [TurnAskingMixin],
  props: {
    primaryType: {
      type: Number,
      default: 0,
    },
    projectInfo: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {
    roomWholePrimaryDiagram,
    monitoringCurve,
    selectOptionsNav,
    NoDataTemporarily,
    SwitchInfo,
  },
  data() {
    return {
      roomPrimaryTimeout: null,
      routeName: '',
      courtToId: '',
      showVideo: false,
      secDiaType: 0,
      selectEquipment: {},
      selectPropInfo: {},
      activeVideoId: 0,
      showDia: false,
      selectOutline: '',
      roomId: '',
      roomName: '',
      courtsInfo: {},
      envirInfo: {},
      branchTree: [],
      diaType: 0, // 0  1 2 概况
      diaSize_0: {
        x: 10,
        y: 10,
      },
      diaSize_1: {
        x: 10,
        y: 10,
      },
      diaTitle_1: '',
      equipType: 0,
      roomInfoList: [],
      selectOptionList: ['1#箱变', '配电室'],
      selectOrder: '',
      subEquipPropsList: [],
      // 设备信息键值对
      subEquipInfoMap: new Map(),
      mapCount: 0,
      subEquipInfoList: [],
    };
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.courtToId);
    next();
  },
  mounted() {
    this.routeName = this.$route.name;
    this.getCourtList();
    this.selectOptionList = this.selectProject.Children;
    this.selectOrder = this.selectOptionList[0].IdStr;
    this.$emit('child-event', this.selectOrder);
  },
  computed: {
    // 属性tableData
    propertiesData() {
      return this.selectEquipment.JCX;
    },
    accountId: {
      get() {
        return this.$store.getters.accountId;
      },
    },
    selectProject() {
      return this.$store.getters.selectProject;
    },

  },
  watch: {
    selectOrder() {
      this.getCourtList();
    },
    mapCount() {
      this.subEquipInfoList = Array.from(this.subEquipInfoMap);
    },
    projectInfo() {
      this.selectOrder = this.selectOptionList[0].IdStr;
      this.$emit('child-event', this.selectOrder);
      this.getCourtList();
    },
    roomInfoList(newVal) {
      if (newVal == null) {
        this.courtsInfo = null;
        return;
      }
      this.courtsInfo = clone(newVal[this.selectOrder]);
    },
  },
  methods: {
    changeEquipMap(list) {
      for (let j = 0; j < list.length; j += 1) {
        const item = list[j];
        const oldProps = this.subEquipInfoMap.get(item.EquipmentID);
        for (let i = 0; i < oldProps.length; i += 1) {
          let prop = oldProps[i];
          if (prop.ID === item.PropertyID) {
            prop = item.PropertyValue;
          }
        }
      }
      this.mapCount += 1;
    },
    subscribeProps(propList, isSub) {
      if (propList.length === 0) {
        return;
      }
      if (isSub === 2) {
        this.removeListEquipments(propList);
      }
    },
    removeListEquipments(propList) {
      for (let i = 0; i < propList.length; i += 1) {
        const item = propList[i];
        this.subEquipInfoMap.delete(item.EquipmentID);
      }
      this.mapCount -= 1;
    },
    // 订阅设备信息
    getSubCourtsProps() {
      let arr = [];
      for (let i = 0; i < this.courtsInfo.TaiQv.length; i += 1) {
        const info = this.courtsInfo.TaiQv[i];
        const transProps = this.getEquipPropFromList(info.BYQ);
        arr = [...arr, ...transProps];

        const incomeLinesProps = this.getEquipPropFromList(info.JX);

        const outLinesProps = this.getEquipPropFromList(info.CX);
        arr = [...arr, ...incomeLinesProps, ...outLinesProps];
      }
      const contactCabinetProps = this.getEquipPropFromList(
        this.courtsInfo.LianLuoGui,
      );
      arr = [...arr, ...contactCabinetProps];
      return arr;
    },
    getEquipPropFromList(list) {
      const propsList = [];
      for (let i = 0; i < list.length; i += 1) {
        const item = list[i];
        const itemProp = {
          EquipmentID: item.TabIDstr,
          List_PropertyID: item.JCX.map((prop) => prop.ID),
        };
        this.subEquipInfoMap.set(item.TabIDstr, item.JCX);
        propsList.push(itemProp);
      }
      this.mapCount += 1;
      return propsList;
    },
    showPropCurve(propInfo, item) {
      this.equipType = propInfo.ID;
      this.selectPropInfo = propInfo;
      this.secDiaType = 0;
      this.diaTitle_1 = `${item.SBMName}--${propInfo.Name}`;
      this.selectEquipment = item;
      this.setDiaSize(10, 10, 1);
      this.showVideo = true;
    },
    // 展示所有属性
    showAllProps(equipInfo, type = this.primaryType ? 3 : 0) {
      this.equipType = type;
      this.secDiaType = 1;
      this.diaTitle_1 = equipInfo.SBMName ? equipInfo.SBMName : equipInfo.Name;
      this.selectEquipment = equipInfo;
      this.setDiaSize(10, 10, 1);
      this.showVideo = true;
    },
    showSwitchControl(equipInfo) {
      // eslint-disable-next-line no-undef
      if (equipInfo.SBMName.indexOf('箱变') !== -1) {
        this.secDiaType = 2;
        this.diaTitle_1 = equipInfo.SBMName ? equipInfo.SBMName : equipInfo.Name;
        this.selectEquipment = equipInfo;
        this.setDiaSize(10, 10, 1);
        this.showVideo = true;
      } else {
        this.showAllProps(equipInfo);
      }
    },
    // 获取选择的Option
    getSelectOption(order) {
      this.selectOrder = order;
      this.$emit('child-event', this.selectOrder);
    },
    // 关掉视频
    closeVideo() {
      this.showVideo = false;
    },
    getCourtList() {
      clearTimeout(this.roomPrimaryTimeout);
      // 根据类型判断需要调用的请求方法
      const requestFunc = this.primaryType ? getLinelossData : getDiagramData;
      requestFunc({
        TaskIDstr: this.selectOrder,
        UIDstr: this.accountId,
      })
        .then((response) => {
          this.courtsInfo = response;
        })
        .catch((failInfo) => {
          console.log(failInfo);
        }).then(() => {
          this.roomPrimaryTimeout = this.turnAsking({
            routeName: this.routeName,
            nowRouteName: this.$route.name,
            func: this.getCourtList,
          }, 5000);
        });
    },
    changeInfosToList(roomInfo) {
      const len = roomInfo.TaiQv.length;
      const realLen = Math.ceil(len / 2);
      const arr = [];
      for (let i = 0; i < realLen; i += 1) {
        const twoCourts = {
          TaiQv: clone(roomInfo.TaiQv.slice(i * 2, (i + 1) * 2)),
          LianLuoGui: clone(roomInfo.LianLuoGui.slice(i, i + 1)),
        };
        arr.push(twoCourts);
      }
      this.roomInfoList = arr;
    },
    getSelectCourtInfo(courtId) {
      for (let i = 0; i < this.roomInfo.C.length; i += 1) {
        const courtInfo = this.roomInfo[i].C[i];
        if (courtInfo.Code === courtId) {
          return courtInfo;
        }
      }
      return null;
    },
    showCurve(equipInfo, type = this.primaryType ? 3 : 0) {
      this.equipType = type;
      this.secDiaType = 1;
      this.diaTitle_1 = equipInfo.SBMName ? equipInfo.SBMName : equipInfo.Name;
      this.selectEquipment = equipInfo;
      this.setDiaSize(10, 10, 1);
      this.showVideo = true;
    },
    requsetOutlineData(outline) {
      console.log(outline);
    },
    setDiaSize(x, y, type) {
      if (type === 0) {
        this.$set(this.diaSize_0, 'x', x);
        this.$set(this.diaSize_0, 'y', y);
      } else {
        this.$set(this.diaSize_1, 'x', x);
        this.$set(this.diaSize_1, 'y', y);
      }
    },
  },
};
</script>
<style scoped>
.no-data-hint{
  font-size: 30px;
  color: #999999;
}
.branchInfo {
  width: 200%;
}
.svg-container {
  overflow-x: hidden;
}
.marquee-control {
  bottom: 3rem;
}
.room-primary {
  width: 100%;
}
.select-option-container {
  top: 3rem;
}
</style>
