<template>
    <div class="switch-title d-flex">
      <div class="switch-item cursor-pointer position-relative"
      v-for="(item,index) of titleList"
      :key="`item-${index}`"
      v-on:click="selectTitle(item)"
      :class="{'select-title': item.value==selectTitleInfo.value}">{{item.title}}</div>
    </div>
</template>
<script>
export default {
  name: 'switch-title',
  props: {
    titleList: {
      type: Array,
      default() {
        return [{
          title: '测试1',
          value: 0,
        }, {
          title: '测试2',
          value: 1,
        }];
      },
    },
  },
  data() {
    return {
      selectTitleInfo: null,
    };
  },
  created() {
    [this.selectTitleInfo] = this.titleList;
  },
  watch: {
    titleList() {
      [this.selectTitleInfo] = this.titleList;
    },
    selectTitleInfo(newVal) {
      this.$emit('selectTitle', newVal);
    },
  },
  methods: {
    selectTitle(titleInfo) {
      this.selectTitleInfo = titleInfo;
    },
  },
};
</script>
<style scoped>
.switch-title {
  color:#778ED3;
}
.select-title{
  color:white;
}
.switch-item{
  padding: 0 1rem;
}
.switch-item + .switch-item:before {
  position: absolute;
  content: " ";
  left:0;
  top:4px;
  width: 1px;
  height: 12px;
  background-color: #778ED3;
}
</style>
