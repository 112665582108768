<template>
  <el-radio-group v-model="selectIndex" size="mini">
    <el-radio-button
      v-for="(option,index) in  selectList"
      :key="'option'+index"
      :value="option.IdStr"
      :label="option.IdStr"
      >{{option.ModelName}}</el-radio-button>
  </el-radio-group>
</template>
<script>
export default {
  name: 'select-options-nav',
  props: {
    selectOption: {
      default: '',
      type: [String, Number],
    },
    selectList: {
      type: Array,
      default() {
        return [{
          IdStr: 'Q0E2NDJBRDUwNzZGQ0JDRg',
          ModelName: '1#箱变配电室',
          oType: 'pds',

        }, {
          IdStr: 'Q0E2NDJBRDUwNzZGQ0JDRg',
          ModelName: '1#箱变配电室',
          oType: 'pds',
        }];
      },
    },
  },
  data() {
    return {
      selectIndex: 0,
    };
  },
  mounted() {
    this.selectIndex = this.selectOption;
  },
  watch: {
    selectOption(newVal) {
      this.selectIndex = newVal;
    },
    selectIndex(newVal) {
      this.$emit('selectOption', newVal);
    },
  },
};
</script>
<style lang="less" scoped>
.select-options-nav {
  font-size: 1.4rem;
  color: #409EFF;
  border: solid 2px #409EFF;
  border-radius: 1.2rem;
  z-index: 99;
  background-color: rgba(160,207,255);
  padding:0 10px;
}
.selected-option {
  color: white;
}
.select-option + .select-option:before {
  content: "|";
  width: 50px;
  height: 20px;
  color: white;
}
// /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{
//   // background: transparent!important;
//   // background-color: #183D7C!important;
//   /* box-shadow: 0px 0px 4px rgba(37, 193, 255, 0.8); */
// }
/deep/.dark .el-radio-button .el-radio-button__inner {
      background-color: #183D7C;
      /* background-color: transparent!important; */
      // box-shadow: 0px 0px 5px rgba(37, 193, 255, 1);
}
/deep/.el-radio-button__inner{
background: transparent!important;
// box-shadow: 0px 0px 5px rgba(37, 193, 255, 1);
}
.el-radio-button{
  // border: 1px solid #25C1FF;
box-shadow: 0px 0px 4px rgba(37, 193, 255, 0.8);
}
</style>
