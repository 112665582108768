import Backup from '@/components/utils/backup.vue';
import SwitchTitle from '@/components/utils/switch-title.vue';
import SectionFrame from '@/components/utils/section-frame.vue';

export default {
  data() {
    return {
      selectTitle: null,
    };
  },
  components: {
    Backup,
    SwitchTitle,
    SectionFrame,
  },
  methods: {
    selectTitleListener(titleInfo) {
      this.selectTitle = titleInfo;
    },
  },
};
