<template>
  <g class="arrow-switch" :transform="isDown | getTransform">
    <line
      x1="13"
      x2="13"
      y1="0"
      y2="15"
      :stroke="isActive ? '#c9141c' : '#0dc734'"
      stroke-width="2"
    ></line>
    <db-arrow-up
      :isDown="isDown"
      :isActive="isActive"
      v-on:clickEvent="$emit('clickEvent')"
    ></db-arrow-up>
    <line
      x1="13"
      x2="13"
      y1="22"
      y2="40"
      :stroke="isActive ? '#c9141c' : '#0dc734'"
      stroke-width="2"
    ></line>
  </g>
</template>
<script>
/*eslint-disable*/
// 箭头开关
import dbArrowUp from "@/components/court-circuit-diagram/svgs/utils/db-arrow-up";
export default {
  name: "arrow-switch",
  components: {
    dbArrowUp,
  },
  props: {
    isActive: {
      default: 0,
      type: [Number, Boolean],
    },
    isDown: {
      default: 0,
      type: [Number, Boolean],
    },
  },
  filters: {
    getTransform: function (isDown) {
      if (isDown) {
        return "translate(26,100),rotate(180)";
      }
      return "";
    },
  },
};
</script>