<template>
    <polygon points="10,0 60,0 35,45" fill="white"></polygon>
</template>
<script>
export default {
  name: 'triangle',
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
  },
};
</script>
