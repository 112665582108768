<template>
  <g
    class="text-list-svg"
    v-on:mouseover.stop="showMoreInfo"
    v-on:mouseout.stop="closeMoreInfo"
  >
     <title>
      <text v-for="(textItem, index) in showListArr" :key="'text-item-title'+
      index" :fill="textItem | filterColor(skinType)">{{
        `${textItem.Name}:${parseFloat(textItem.Value).toFixed(2)}${
          textItem.Unit?textItem.Unit: ''
        }\n`
      }}</text>
    </title>
    <g
      v-bind:style="{ fontSize: `${textSize*1.8}rem` }"
      v-on:click="$emit('clickEvent')"
    >
      <text
        v-for="(textItem, index) in showListArr"
        :transform="`translate(0,${26 * index * textSize})`"
        :key="'text-item-' + index"
        :fill="textItem | filterColor(skinType)"
        v-on:click="$emit('propClick', textItem)"
      >
        {{textItem.ID==202?parseInt(textItem.Value):  parseFloat(textItem.Value).toFixed(2) }}
      </text>
    </g>
  </g>
</template>
<script>
export default {
  name: 'text-list-svg',
  props: {
    title: {
      default: '',
      type: String,
    },
    showPropertyType: {
      default: 0, // 0电流 1电压 2三相温度,3线损 10 电流
      type: Number,
    },
    propertyArr: {
      type: Array,
      default() {
        return [];
      },
    },
    textSize: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rectSize: {
        width: 150,
        height: 120,
        isDash: 0,
        isBorder: 0,
      },
      secPropArr: [],
      lineArr: [],
      showListArr: [],
      primaryType: 0,
      isShowMore: false,
    };
  },
  mounted() {
    this.primaryType = this.$route.params.primaryType;
    this.showListArr = this.getShowList(this.propertyArr);
  },
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
  },
  watch: {
    showPropertyType() {
      this.showListArr = this.getShowList(this.propertyArr);
    },
    propertyArr(newVal) {
      this.showListArr = this.getShowList(newVal);
    },
    secPropArr(newVal) {
      this.showListArr = this.getShowList(newVal);
    },
    lineArr(newVal) {
      this.showListArr = this.getShowList(newVal);
    },
  },
  filters: {
    filterColor(propertyItem, skinType) {
      switch (parseInt(propertyItem.ID, 10)) {
        case 172:
        case 217:
        case 188:
        case 328:
        case 192:
          return '#ffc107';
        case 173:
        case 218:
        case 189:
        case 329:
        case 193:
          return '#28a745';
        case 174:
        case 219:
        case 190:
        case 330:
        case 194:
          return '#dc3545';
        case 175:
        case 178:
          return '#ffc107';
        case 176:
        case 179:
          return '#28a745';
        case 177:
        case 180:
          return '#dc3545';
        case 202:
          return skinType ? '#fff' : '#333';
        default:
          return '';
      }
    },
  },
  methods: {
    showMoreInfo() {
      this.isShowMore = true;
    },
    closeMoreInfo() {
      this.isShowMore = false;
    },
    initialSecPropArr() {
      this.secPropArr = [];
    },
    // 线损率
    lineLoss() {
      if (this.propertyArr.length > 0) {
        if (this.propertyArr.length === 3) {
          this.lineArr = [].concat(this.propertyArr);
        } else {
          this.lineArr = this.propertyArr;
        }
      } else {
        if (this.showPropertyType === 11) {
          this.lineArr = [];
          return;
        }
        this.lineArr = [];
      }
    },
    getShowList(list, type) {
      const showList = [];
      const minMaxIds = this.getMinMaxIds(type);
      minMaxIds.forEach((minMax) => {
        list.forEach((item) => {
          if (item && item.ID > minMax.min && item.ID < minMax.max) {
            showList.push(item);
          }
        });
      });
      return showList;
    },
    getMinMaxIds(showPropertyType = this.showPropertyType) {
      switch (showPropertyType) {
        case 0:
          return [
            { min: 171, max: 175 },
            { min: 174, max: 178 },
            { min: 216, max: 220 },
            { min: 201, max: 203 },
          ];
        case 1:
          return [{ min: 174, max: 178 }];
        case 2: // 温度
          return [{ min: 216, max: 220 }];
        case 3:
          return [{ min: 327, max: 331 }];
        case 4:
          return [{ min: 191, max: 195 }];
        case 5:
          return [{ min: 201, max: 203 }];
        case 10:
          return [{ min: 171, max: 175 }];
        case 11:
          return [
            { min: 171, max: 175 },
            { min: 174, max: 178 },
          ];
        default:
          return [
            { min: 171, max: 175 },
            { min: 187, max: 189 },
            { min: 189, max: 191 },
            { min: 188, max: 190 },
            { min: 216, max: 220 },
          ];
      }
    },
  },
};
</script>
