<template>
  <div class="section-info full-bg d-flex flex-column">
    <div v-if="title" class="section-title full-bg">{{ title }}</div>
      <slot ></slot>
  </div>
</template>
<script>
export default {
  name: 'section-info',
  props: {
    title: {
      type: String,
      default: '',
    },
    tableData: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
<style scoped>
.section-info {
  background: linear-gradient(0deg, #1c4596, #181d62);
  border: 2px solid #1b71cc;
}
.section-title {
  line-height: 2rem;
  color:#fff;
  text-align: left;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.table,
.table tr th,
.table tr td {
  border: 2px solid #1354ca;
}
.table td,
.table th {
  padding: 0.25rem;
}

.section-body {
  margin: 0 1rem;
}
.table {
  margin-bottom: 0;
}
</style>
