// deep clone Object Array
const clone = (obj) => {
  let o;
  if (typeof obj === 'object') {
    // 如果  他是空的话
    if (obj === null) {
      o = null;
    } else if (obj instanceof Array) {
      o = [];
      for (let i = 0, len = obj.length; i < len; i += 1) {
        o.push(clone(obj[i]));
      }
    } else {
      o = {};
      for (let j = 0; j < Object.keys(obj).length; j += 1) {
        const key = Object.keys(obj)[j];
        o[key] = clone(obj[key]);
      }
    }
  } else {
    o = obj;
  }
  return o;
};

export default clone;
