import { request } from '@/utils/request/request';

// 获取配电室一次图数据
export function getDiagramData(data) {
  return request({
    url: 'api/APPAPI_NEW/v1/JPTQ_YiCiTu',
    method: 'get',
    params: data,
  });
}
// 获取线损数据
export function getLinelossData(data) {
  return request({
    url: 'api/APPAPI_NEW/v1/JPTQ_YiCiTu_XS',
    method: 'GET',
    params: data,
  });
}
// 获取线损概况信息
export function getLinelossGeneralInfo(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/JPTQ_StateEstimation',
    method: 'GET',
    params,
  });
}
// 状态评价信息
export function getShebeiInfo(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/JPTQ_OperationOverview_List',
    method: 'GET',
    params,
  });
}

// 获取设备某属性的对比曲线
export function getComparativeCurveData(data) {
  return request({
    url: 'api/APPAPI_NEW/v1/JPTQ_GetQvXian_By_SBIDstr_DoubleLine',
    method: 'get',
    params: data,
  });
}
// 获取配电室环境
export function getSwitchRoomEnvir(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/JPTQ_PDS_HuanJing',
    method: 'GET',
    params,
  });
}
export function getInSwitchInfo(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/NA1P_BreakerInfo_CHNT',
    method: 'GET',
    params,
  });
}
