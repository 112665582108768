<template>
  <el-row type="flex" class="flex-grow-1 d-flex align-items-stretch">
    <el-col
      :span="8"
      class="d-flex flex-column justify-content-around align-items-stretch"
    >
      <section-info title="设备状况" class="section-container">
        <InfoRow :infoList="EQUIPMENT_STATE"></InfoRow>
      </section-info>
      <section-info title="三相电压" class="section-container">
        <InfoRow :infoList="THREE_PHASE_VOLTAGE"></InfoRow>
      </section-info>
      <section-info
        title="三相电流"
        class="section-container"
        :tableData="threePhaseElectric"
      >
        <InfoRow :infoList="THREE_PHASE_ELECTRICITY"></InfoRow>
      </section-info>
      <section-info class="section-container none-height flex-grow-1">
        <div class="d-flex flex-grow-1 justify-content-around">
          <alerting-signal
            v-for="(alertingSignal, index) of WARN_STATE"
            :key="'alerting' + index"
            :signalInfo="alertingSignal"
          ></alerting-signal>
        </div>
      </section-info>
      <!-- <section-info class="section-container none-height flex-grow-1" v-show="false">
        <div class="d-flex flex-grow-1 flex-column" style="padding: 1rem 0;">
          <WarnItem></WarnItem>
        </div>
      </section-info> -->
      <!-- <section-info title="" class="section-container none-height flex-grow-1">
        <div class="overload" style="color:red">
          过载长延时故障2019-9-12 12:33:30
          </div>
      </section-info> -->
      <section-info title="" class="section-container none-height flex-grow-1">
        <div class="d-flex flex-grow-1 justify-content-around">
        <!-- <InfoRow :infoList="THREE_PHASE_VOLTAGE"></InfoRow> -->
        <!-- <div class="overload" style="color:red">
          过载长延时故障2019-9-12 12:33:30
          </div> -->
          <alerting-signal
            v-for="(alertingSignal, index) of WARN_STATE_EXT"
            :key="'alerting' + index"
            :signalInfo="alertingSignal"
          ></alerting-signal>
        </div>
      </section-info>
    </el-col>
    <el-col
      :span="8"
      class="d-flex flex-column justify-content-center align-items-center"
      style="padding: 6rem 1rem"
    >
      <img class="img-fluid" style="width:80%;" :src="switchImg" />
      <!-- <el-row class="d-flex justify-content-around" style="margin-top: 2rem">
        <el-button :type="switchInfo['开关分合闸状态']?'danger':'primary'" size="mini">分闸</el-button>
        <el-button :type="switchInfo['开关分合闸状态']?'primary':'danger'" size="mini">合闸</el-button>
        <el-button :type="switchInfo['断路器位置状态']===1?'danger':'primary'" size="mini">连接</el-button>
        <el-button :type="switchInfo['断路器位置状态']?'primary':'danger'" size="mini">分离</el-button>
        <el-button :type="switchInfo['断路器位置状态']===2?'danger':'primary'" size="mini">试验</el-button>
      </el-row> -->
      <el-row class="d-flex justify-content-around" style="margin-top: 2rem">
        <el-button :type="switchInfo['开关分合闸状态']==0?'danger':'primary'" size="mini">分闸</el-button>
        <el-button :type="switchInfo['开关分合闸状态']==0?'primary':'danger'" size="mini">合闸</el-button>
        <el-button :type="switchInfo['连接']==1?'danger':'primary'" size="mini">连接</el-button>
        <el-button :type="switchInfo['分离']==1?'danger':'primary'" size="mini">分离</el-button>
        <el-button :type="switchInfo['试验']==1?'danger':'primary'" size="mini">试验</el-button>
      </el-row>
    </el-col>
    <el-col
      :span="8"
      class="d-flex flex-column justify-content-around align-items-stretch"
    >
      <section-info
        title="有功功率"
        class="section-container"
        :tableData="powerStatus"
      >
        <InfoRow :infoList="POWER"></InfoRow>
      </section-info>
      <section-info
        title="无功功率"
        class="section-container"
        :tableData="threePhaseCurrent"
      >
        <InfoRow :infoList="THREE_PHASE_NEED_ELECTRICITY"></InfoRow>
      </section-info>
      <section-info
        title="功率因素"
        class="section-container"
        :tableData="totalRequirePower"
      >
        <InfoRow :infoList="NEED_POWER"></InfoRow>
      </section-info>
      <section-info
        title="生命周期"
        class="section-container none-height flex-grow-1 my-1"
      >
        <Charts class="flex-grow-1 being" :options="vitaData"></Charts>
      </section-info>
    </el-col>
  </el-row>
</template>
<script>
import SectionInfo from '@/components/project-operation-monitor/switch-info/section-info.vue';
import switchImg from '@/assets/images/project/operation-monitor/switch-info/switch.png';
import alertingSignal from '@/components/project-operation-monitor/switch-info/alerting-signal.vue';
import closeImg from '@/assets/images/project/operation-monitor/switch-info/close.png';
import closeAlertingImg from '@/assets/images/project/operation-monitor/switch-info/close-alerting.png';
import electricalMachineryImg from '@/assets/images/project/operation-monitor/switch-info/electrical-machinery.png';
import electricalMachineryAlertingImg from '@/assets/images/project/operation-monitor/switch-info/electrical-machinery-alerting.png';
import seperationImg from '@/assets/images/project/operation-monitor/switch-info/separation.png';
import seperationAlertingImg from '@/assets/images/project/operation-monitor/switch-info/separation-alerting.png';
import undervoltageImg from '@/assets/images/project/operation-monitor/switch-info/undervoltage.png';
import undervoltageAlertingImg from '@/assets/images/project/operation-monitor/switch-info/undervoltage-alerting.png';
// import Charts from '@/components/utils/charts.vue';
import InfoRow from '@/components/project-operation-monitor/switch-info/info-row.vue';
import * as switchPropInfo from '@/mock/project';
// import WarnItem from '@/components/project-operation-monitor/switch-info/warn-item.vue';
import { getInSwitchInfo } from '@/api/switchroom';
import guoyaImg from '@/assets/images/project/operation-monitor/switch-info/guoya.png';
import guoyaAlertingImg from '@/assets/images/project/operation-monitor/switch-info/gy.png';
import guopinImg from '@/assets/images/project/operation-monitor/switch-info/guopin.png';
import guopinAlertingImg from '@/assets/images/project/operation-monitor/switch-info/gp.png';
import nigonglvImg from '@/assets/images/project/operation-monitor/switch-info/nigonglv.png';
import nigonglvAlertingImg from '@/assets/images/project/operation-monitor/switch-info/ngl.png';
import txcsImg from '@/assets/images/project/operation-monitor/switch-info/tongxunchaoshi.png';
import txcsAlertingImg from '@/assets/images/project/operation-monitor/switch-info/txcs.png';
import Charts from '../../general/chart.vue';

export default {
  name: 'switch-info',
  components: {
    SectionInfo,
    alertingSignal,
    Charts,
    InfoRow,
    // WarnItem,
  },
  props: {
    equipInfo: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      switchInfo: null,
      alertOptions: {
        grid: {
          left: '0%',
          right: '0',
          top: '5%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLine: { lineStyle: { color: '#63b8d5' } },
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          type: 'value',
          axisLine: { lineStyle: { color: '#63b8d5' } },
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true,
          },
        ],
      },
      alertingSignalList: [
        {
          normalImg: undervoltageImg,
          alertingImg: undervoltageAlertingImg,
          isAlerting: 0,
          title: '欠压报警',
        },
        {
          normalImg: seperationImg,
          alertingImg: seperationAlertingImg,
          isAlerting: 0,
          title: '欠频报警',
        },
        {
          normalImg: closeImg,
          alertingImg: closeAlertingImg,
          isAlerting: 0,
          title: '相序报警',
        },
        {
          normalImg: electricalMachineryImg,
          alertingImg: electricalMachineryAlertingImg,
          isAlerting: 0,
          title: '自诊断报警',
        },
      ],
      alertingSignalList_EX: [
        {
          normalImg: guoyaImg,
          alertingImg: guoyaAlertingImg,
          isAlerting: 0,
          title: '过压报警',
        },
        {
          normalImg: guopinImg,
          alertingImg: guopinAlertingImg,
          isAlerting: 0,
          title: '过频报警',
        },
        {
          normalImg: nigonglvImg,
          alertingImg: nigonglvAlertingImg,
          isAlerting: 0,
          title: '逆功率报警',
        },
        {
          normalImg: txcsImg,
          alertingImg: txcsAlertingImg,
          isAlerting: 0,
          title: '通讯超时',
        },
      ],
      switchImg,
      equipStatus: {
        headers: ['电寿命', '机械寿命', '触头磨碎'],
        contents: [
          [
            {
              value: 100,
              unit: '%',
            },
            {
              value: 100,
              unit: '%',
            },
            { value: 100, unit: '%' },
          ],
        ],
      },
      threePhaseVoltage: {
        headers: ['A相电压', 'B相电压', 'C相电压'],
        contents: [
          [
            {
              value: 230.5,
              unit: 'V',
            },
            {
              value: 230.5,
              unit: 'V',
            },
            { value: 230.0, unit: 'V' },
          ],
        ],
      },
      threePhaseElectric: {
        headers: ['A相电流', 'B相电流', 'C相电流'],
        contents: [
          [
            {
              value: 0,
              unit: 'A',
            },
            {
              value: 0,
              unit: 'A',
            },
            { value: 0, unit: 'A' },
          ],
        ],
      },
      powerStatus: {
        headers: ['A相有功功率', 'B相有功功率', 'C相有功功率'],
        contents: [
          [
            {
              value: -343,
              unit: 'kW',
            },
            {
              value: 425,
              unit: 'kVar',
            },
            { value: 636, unit: 'kVA' },
          ],
        ],
      },
      totalRequirePower: {
        headers: ['总需用有功功率', '总需用无功功率', '总需用现在功率'],
        contents: [
          [
            {
              value: -342,
              unit: 'kW',
            },
            {
              value: 424,
              unit: 'kVar',
            },
            { value: 635, unit: 'kVA' },
          ],
        ],
      },
      threePhaseCurrent: {
        headers: ['A相需要电流', 'B相需要电流', 'C相需要电流'],
        contents: [
          [
            {
              value: 846,
              unit: 'A',
            },
            {
              value: 841,
              unit: 'A',
            },
            { value: 874, unit: 'A' },
          ],
        ],
      },
      vitaData: {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          top: '40',
          left: '10',
          right: '20',
          bottom: '20',
          containLabel: true,
        },
        legend: {
          top: '5',
          left: 'left',
          icon: 'circle',
          itemWidth: 10, // 设置宽度
          itemHeight: 10,
          textStyle: {
            color: 'auto',
          },
        },
        xAxis: [
          {
            type: 'category',
            // data: [20, 52, 100, 34, 30, 150, 220],
            data: ['负压生命周期', '分励生命周期', '闭合生命周期', '电机生命周期'],
            // data: this.data3.xAxisData,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff',
              },
            },
            axisLabel: {
              interArrival: 0,
              rotate: 30,

            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            nameTextStyle: {},
            axisLabel: {
              show: true,
              formatter: '{value}%',
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff',
              },
            },
            splitLine: {
              lineStyle: {
                color: '#ccc',
                opacity: '0.23',
                type: 'dashed',
              },
            },
          },
          {
            // 隐藏掉右边的纵坐标
            show: false,
            type: 'value',
            name: '负载率(%)',
            nameTextStyle: {
              color: '#fff',
            },
            axisLabel: {
              color: '#fff',
            },
            axisLine: {
              color: '#fff',
            },
            splitLine: {
              lineStyle: {
                color: '#ccc',
                type: 'dashed',
              },
            },
          },
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barGap: '220%',
            yAxisIndex: 0,
            barWidth: '15%', // 柱图宽度,
            label: {
              show: true, // 开启显示
              position: 'top', // 在上方显示
              verticalAlign: 'middle',
              textStyle: {
                // 数值样式
                color: '#fff',
                fontSize: 10,
              },
            },
            color: {
              type: 'linear', // 线性渐变
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#00BEFE', // 0%处的颜色为红色
                },
                {
                  offset: 1,
                  color: '#007EEE', // 100%处的颜色为蓝
                },
              ],
            },
            // data: this.data3.seriesData[0].Data,
            data: [98, 100, 99, 100],
          },
          // {
          //   name: '负载率',
          //   type: 'bar',
          //   yAxisIndex: 1,
          //   barWidth: '15%', // 柱图宽度
          //   label: {
          //     show: true, // 开启显示
          //     position: 'top', // 在上方显示
          //     // distance: 20, // 距离图形元素的距离。当 position 为字符描述值（如 'top'、'insideRight'）时候有效。
          //     verticalAlign: 'middle',
          //     textStyle: {
          //       // 数值样式
          //       color: '#fff',
          //       fontSize: 10,
          //     },
          //   },
          //   color: {
          //     type: 'linear', // 线性渐变
          //     x: 0,
          //     y: 0,
          //     x2: 0,
          //     y2: 1,
          //     colorStops: [
          //       {
          //         offset: 0,
          //         color: '#FFAF47', // 0%处的颜色为红色
          //       },
          //       {
          //         offset: 1,
          //         color: '#F9912C', // 100%处的颜色为蓝
          //       },
          //     ],
          //   },
          //   // data: this.data3.seriesData[1].Data,
          //   data: [20, 52, 100, 34, 30, 150, 220],
          //   // data: ['负压生命周期', '分励生命周期', '闭合生命周期', '电机生命周期'],
          // },
        ],
        color: [
          '#0079ED',
          '#F88C28',
          '#667EEB',
          '#75B2FB',
          '#08BED3',
          '#F67CA3',
          '#7FC4C2',
          '#7a81b5',
        ],
      },
    };
  },
  created() {
    this.requestSwitchInfo();
  },
  watch: {
    equipInfo() {
      this.requestSwitchInfo();
    },
  },
  computed: {
    EQUIPMENT_STATE() {
      if (!this.switchInfo) {
        return null;
      }
      return switchPropInfo.EQUIPMENT_STATE.map((item) => ({
        name: item.name,
        value: this.switchInfo[item.name],
        unit: item.unit,
      }));
    },
    THREE_PHASE_VOLTAGE() {
      if (!this.switchInfo) {
        return null;
      }
      return switchPropInfo.THREE_PHASE_VOLTAGE.map((item) => ({
        name: item.name,
        value: this.switchInfo[item.name],
        unit: item.unit,
      }));
    },
    THREE_PHASE_ELECTRICITY() {
      if (!this.switchInfo) {
        return null;
      }
      return switchPropInfo.THREE_PHASE_ELECTRICITY.map((item) => ({
        name: item.name,
        value: this.switchInfo[item.name],
        unit: item.unit,
      }));
    },
    POWER() {
      if (!this.switchInfo) {
        return null;
      }
      return switchPropInfo.POWER.map((item) => ({
        name: item.name,
        value: this.switchInfo[item.name],
        unit: item.unit,
      }));
    },
    THREE_PHASE_NEED_ELECTRICITY() {
      if (!this.switchInfo) {
        return null;
      }
      return switchPropInfo.THREE_PHASE_NEED_ELECTRICITY.map((item) => ({
        name: item.name,
        value: this.switchInfo[item.name],
        unit: item.unit,
      }));
    },
    NEED_POWER() {
      if (!this.switchInfo) {
        return null;
      }
      return switchPropInfo.NEED_POWER.map((item) => ({
        name: item.name,
        value: this.switchInfo[item.name],
        unit: item.unit,
      }));
    },
    WARN_STATE() {
      if (!this.switchInfo) {
        return null;
      }
      return switchPropInfo.WARN_STATE.map((item, index) => {
        const signalItem = this.alertingSignalList[index];
        return Object.assign(signalItem, {
          title: item.name,
          value: parseInt(this.switchInfo[item.name], 10),
          isAlerting: this.switchInfo[item.name],
        });
      });
    },
    WARN_STATE_EXT() {
      if (!this.switchInfo) {
        return null;
      }
      return switchPropInfo.WARN_STATE_EXT.map((item, index) => {
        const signalItem = this.alertingSignalList_EX[index];
        return Object.assign(signalItem, {
          title: item.name,
          value: parseInt(this.switchInfo[item.name], 10),
          isAlerting: this.switchInfo[item.name],
        });
      });
    },
  },
  methods: {
    requestSwitchInfo() {
      if (!this.equipInfo) {
        return;
      }
      getInSwitchInfo({
        SBIDstr: this.equipInfo.TabIDstr,
      }).then((res) => {
        console.log(res);
        this.switchInfo = res;
      });
    },
  },
};
</script>
<style scoped>
.img-fluid {
  width: 100%;
}
.separating-break {
  background: linear-gradient(to top, #650505, #ff3b3b);
}
.switch-on {
  background: linear-gradient(to top, #628808, #bcf604);
}
.btn {
  width: 10rem;
}
.section-container + .section-container {
  margin-top: 0.6rem;
}
.section-container {
  padding: 0.4rem 1.6rem;
}
/*  */
.el-button--primary{
  background-color:transparent;
  box-shadow: 0px 0px 4px rgba(37, 193, 255, 0.8);
}
.overload{
  text-align: center;
  margin:10% auto;
}
/* .overload span{
  width: 100%;
  border:1px solid red;
  background-color: #bcf604;
} */
/* 生命周期 */
.being{
  width: 100%;
  height: 30%;
}
</style>
