<template>
  <div class="monitoring-curve d-flex flex-column flex-grow-1">
    <el-form :inline="true" size="small">
      <el-form-item label="对比日期(一)">
        <el-date-picker
          type="date"
          placeholder="选择日期"
          v-model="curveParams.selectDate1"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="对比日期(二)">
        <el-date-picker
          type="date"
          placeholder="选择日期"
          v-model="curveParams.selectDate2"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="setCurveParams">查询</el-button>
      </el-form-item>
    </el-form>
    <equipment-props-curve
      :equipType="equipType"
      :curveParams="curveParams"
    ></equipment-props-curve>
  </div>
</template>
<script>
import equipmentPropsCurve from '@/components/general/equipment-props-curve.vue';
import { formatDate, getDefaultDateByType } from '@/utils/utils';

export default {
  name: 'monitoring-curve',
  components: {
    equipmentPropsCurve,
  },
  props: {
    propInfo: {
      type: Object,
      // default: '',
      default() {
        return {};
      },
    },
    equipType: {
      type: Number,
      default: 0,
    },
    equipmentInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      formatDate,
      curveParams: {
        propUnit: '',
        quatityType: 0,
        eId: '', // 设备名称
        propIds: '172,173,174', // 设备属性
        periodType: 0, // 日期类型
        selectDate1: formatDate(
          new Date(new Date() - 1000 * 60 * 60 * 24),
          'yyyy-MM-dd',
        ), // 选择日期
        selectDate2: formatDate(new Date(), 'yyyy-MM-dd'), // 选择日期
      },
      options: ['电流', '电压', '有功功率'],
      selectOption: 0,
      courtId: '',
      quatityId: '',
      boundNames: ['过电压', '低电压'],
      boundArr: [235.4, 198],
      massValue: '',
      xSeries: [],
      names: [],
      mapArr: [],
      colors: ['yellow', 'green', 'red', 'white'],
      queryDate: {
        start: `${formatDate(new Date(), 'yyyy-MM-dd')} 00:00`,
        end: `${formatDate(new Date(), 'yyyy-MM-dd')} 23:59`,
      },
      selectPeriodType: 0,
      selectDate: formatDate(new Date(), 'yyyy-MM-dd'),
      quatityType: 0,
      showDia: 0,
      loadInfo: {
        min: 0.25,
        max: 0.45,
      },
      dateInputTypes: ['date', 'week', 'month'],
      diaData: {
        title: '电压质量',
      },
      loadQuatity: [
        {
          icon: 'icon-maximum',
          words: '最大值',
          color: '',
          hint: '负载率:',
          content: '1.38%',
        },
        {
          icon: 'icon-least',
          words: '最小值',
          color: '',
          hint: '负载率:',
          content: '0.79%',
        },
      ],
      loadrateArr: [
        {
          icon: 'icon-c1',
          words: '三相',
          color: '',
          hint: '三相不平衡',
          content: '8.96%',
        },
      ],
      qualityArr: [
        {
          icon: 'icon-maximum',
          words: '过电压',
          color: '',
          hint: '过电压用户数:',
          content: 0,
        },
        {
          icon: 'icon-least',
          words: '低电压',
          color: '',
          hint: '低电压用户数:',
          content: 0,
        },
      ],
      loadrateData: {
        title: '',
        legendData: [''],
        xAxisData: [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
        ],
        yUnit: '%',
        yAxisData: '',
        seriesData: [
          {
            name: '三相不平衡率',
            type: 'line',
            stack: '总量',
            smooth: true,
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
          },
        ],
      },
      chartDatas: {
        title: '',
        xAxisData: {
          data: [
            '00:00',
            '01:00',
            '02:00',
            '03:00',
            '04:00',
            '05:00',
            '06:00',
            '07:00',
            '08:00',
            '09:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00',
            '22:00',
            '23:00',
          ],
          interval: 2,
        },
        yUnit: '%',
        yAxisData: '',
        legendData: [''],
        seriesData: [
          {
            name: 'A相',
            type: 'line',
            smooth: true,
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
          },
          {
            name: 'B相',
            type: 'line',
            smooth: true,
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
          },
          {
            name: 'C相',
            type: 'line',
            smooth: true,
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
          },
        ],
        colors: ['yellow', 'green', 'red', 'white'],
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.quatityId) {
      clearTimeout(this.quatityId);
    }
    next();
  },
  mounted() {
    this.changeCurveParams();
    this.chartDatas = this.loadrateData;
    console.log(222222222222222222);
    this.setCurveParams();
    console.log(this.curveParams);
  },
  computed: {
    selectArea: {
      get() {
        return this.$store.getter.selectArea;
      },
      set() {},
    },
    accountId: {
      get() {
        return this.$store.getter.accountId;
      },
      set() {},
    },
  },
  watch: {
    quatityType() {},
    chartData: {
      deep: true,
      handler() {},
    },
    chartDatas: {
      deep: true,
      handler() {},
    },
    selectPeriod(newVal) {
      this.selectDate = getDefaultDateByType(newVal);
    },
    mapArr: {
      deep: true,
      handler() {
        this.changeChartData();
      },
    },
    loadrateData: {
      deep: true,
      handler() {
        this.chartDatas = {
          colors: this.colors,
          ...this.loadrateData,
        };
      },
    },
    equipmentInfo: {
      deep: true,
      handler() {
        this.changeCurveParams();
      },
    },
    propInfo: {
      deep: true,
      handler() {
        this.changeCurveParams();
      },
    },
  },
  methods: {
    changeCurveParams() {
      this.$set(this.curveParams, 'eId', this.equipmentInfo.TabIDstr);
      this.$set(this.curveParams, 'propIds', this.propInfo.ID);
    },
    getQuatityType(type) {
      this.quatityType = type;
    },
    setCurveParams() {
      this.curveParams.propUnit = this.propInfo ? this.propInfo.Unit : '';
      this.curveParams.quatityType = this.quatityType;
      this.curveParams.periodType = this.selectPeriodType;
      this.curveParams.selectDate = this.selectDate;
      this.curveParams.propIds = this.getPropertyIDs(this.quatityType);
    },
    getPeriodDate(periodType = 0, date = formatDate(new Date(), 'yyyy-MM-dd')) {
      this.selectPeriodType = periodType;
      this.selectDate = date;
    },
    getPropertyIDs(type) {
      if (this.equipType > 0) {
        return `${this.equipType}`;
      }
      switch (type) {
        case 0:
          return '172,173,174';
        case 1:
          return '175,176,177';
        case 2:
          return '202';
        case 3:
          return '328,329,330';
        default:
          return '';
      }
    },
  },
};
</script>
<style scoped>
.but {
  background-color: #047efe;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 4rem;
  height: 2.2rem;
}
</style>
