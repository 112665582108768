//设备属性曲线
<template>
  <div class="flex-grow-1 equipment-props-curve d-flex flex-column">
    <charts class="flex-grow-1" :chartId="chartId" :options="chartDatas"></charts>
  </div>
</template>
<script>
import Charts from '@/components/general/chart.vue';
import { getComparativeCurveData } from '@/api/switchroom';

export default {
  name: 'equipment-props-curve',
  props: {
    chartId: {
      default: 'equipment-props-curve-charts',
      type: String,
    },
    equipType: {
      type: Number,
      default: 0,
    },
    curveParams: {
      type: Object,
      default() {
        return {
          propUnit: '',
          quatityType: 0,
          eId: '', // 设备名称
          propIds: '', // 设备属性
          periodType: '', // 日期类型
          selectDate: '', // 选择日期
        };
      },
    },
  },
  components: {
    Charts,
  },
  data() {
    return {
      mapArr: [],
      xSeries: [],
      boundArr: [],
      boundNames: [],
      colors: ['yellow', 'green', 'red'],
      chartDatas: null,
    };
  },
  mounted() {
    this.getCurveByEquipment();
  },
  watch: {
    mapArr: {
      deep: true,
      handler() {
        this.changeChartData();
      },
    },
    curveParams: {
      deep: true,
      handler() {
        this.getCurveByEquipment();
      },
    },
    accountId() {
      this.getCurveByEquipment();
    },
    chartDatas: {
      deep: true,
      handler(newVal) {
        console.log('chartDatas', newVal);
      },
    },
    skinType() {
      this.changeChartData();
    },
  },
  computed: {
    accountId: {
      get() {
        return this.$store.getters.accountId;
      },
    },
    skinType() {
      return this.$store.getters.skinType;
    },
  },
  methods: {
    // 电压电流曲线
    changeChartData() {
      this.chartDatas = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: this.names,
          textStyle: {
            color: 'auto',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xSeries,
          axisLabel: {
            color: '#fff',
          },
          axisLine: {
            color: '#fff',
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff',
          },
          axisLine: {
            color: '#333',
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },

          },
        },
        yUnit: this.getUnit(),
        ...this.getShowBounds(),
        series: this.getSeriesData(),
      };
    },
    getShowBounds() {
      const maxArr = [];
      const minArr = [];
      this.mapArr.forEach((map) => {
        const realArr = this.getNoNullArr(map);
        maxArr.push(Math.max(...realArr));
        minArr.push(Math.min(...realArr));
      });
      let min = parseInt(Math.min(...this.boundArr, ...minArr) - 1, 10);
      const max = parseInt(Math.max(...this.boundArr, ...maxArr) + 1, 10);
      if (min < 0) {
        min = 0;
      }
      if (Number.isNaN(min)) {
        min = 0;
      }
      if (Number.isNaN(max)) {
        return {};
      }
      return {
        min: Math.floor(min / 10.0) * 10,
        max: Math.ceil(max / 10.0) * 10,
      };
    },
    getNoNullArr(arr) {
      return arr.filter((item) => item !== null && item !== '' && typeof (item) !== 'undefined');
    },
    getSeriesData() {
      const arr = [];
      const warnLines = this.getMarkLineData();
      this.mapArr.forEach((mArr, index) => {
        let markLine = {};
        if (index === 0) {
          markLine = {
            markLine: {
              lineStyle: {
                color: 'red',
                type: 'dashed',
              },
              data: warnLines,
            },
          };
        }
        const data = {
          name: this.names[index],
          type: 'line',
          smooth: true,
          data: mArr,
          ...markLine,
        };
        arr.push(data);
      });
      return arr;
    },
    getMarkLineData() {
      return this.boundArr.map((bound, index) => ({
        yAxis: bound,
        label: {
          position: 'middle',
          formatter: `${this.boundNames[index % this.boundNames.length]}-{c}`,
        },
      }));
    },
    getCurveByEquipment() {
      getComparativeCurveData({
        UIDstr: this.accountId,
        SBIDstr: this.curveParams.eId,
        PropertyID: this.curveParams.propIds,
        Type_Date1: this.curveParams.selectDate1,
        Type_Date2: this.curveParams.selectDate2,
      }).then((response) => {
        this.getShowChartData(response);
      })
        .catch((fail) => {
          console.log(fail);
        });
    },
    getUnit() {
      if (this.curveParams.propUnit) {
        return this.curveParams.propUnit;
      }
      if (this.equipType > 0) {
        switch (this.equipType) {
          case 210:
            return '℃';
          case 211:
            return '%';
          default:
            return '';
        }
      }
      switch (this.curveParams.quatityType) {
        case 0:
          return 'A';
        case 1:
          return 'V';
        case 2:
          return 'kW';
        case 3:
          return 'A';
        default:
          return '';
      }
    },
    getShowChartData(curveData) {
      this.boundNames = [];
      this.boundArr = [];
      this.getCurveData(curveData);
    },
    getWarnLines(boundData) {
      const boundMap = new Map();
      boundData.forEach((bound) => {
        const boundData1 = this.getBoundValue(bound);
        if (!boundData1.min && boundData1.max) {
          boundMap.set(boundData1.max, bound.RName);
        }
        if (boundData1.min) {
          boundMap.set(boundData1.min, bound.RName);
        }
      });
      this.boundNames = ['过电压', '低电压'];
      this.boundArr = [235.4, 198];
    },
    getBoundValue(bound) {
      const boundData = {};
      if (typeof bound.VMin === 'number') {
        if (bound.VType) {
          boundData.min = bound.VMin * parseInt(bound.JiZhunZhi, 10);
        } else {
          boundData.min = bound.VMin;
        }
      }
      if (typeof bound.VMax === 'number') {
        if (bound.VType) {
          boundData.max = bound.VMax * parseInt(bound.JiZhunZhi, 10);
        } else {
          boundData.max = bound.VMax;
        }
      }
      return boundData;
    },
    getCurveData(data) {
      this.xSeries = data.xAxisData;
      const mapArr = [];
      this.names = [];
      const seriesData = data.seriesData.sort(
        (a, b) => a.ShuXingID - b.ShuXingID,
      );
      seriesData.forEach((series) => {
        if (seriesData.length > 1) {
          this.names.push(series.GroupName);
        }
        mapArr.push(this.changeArrToMap(series.List));
      });
      this.changeMapToArr(mapArr);
    },
    changeNoToName(no) {
      switch (no) {
        case 175:
          return 'A';
        case 176:
          return 'B';
        case 177:
          return 'C';
        default:
          return '';
      }
    },
    changeArrToMap(arr) {
      const map = new Map();
      arr.forEach((item) => {
        map.set(item.Time, item.Value);
      });
      return map;
    },
    changeMapToArr(mapArr) {
      const arrs = [];
      mapArr.forEach((map) => {
        const arr = [];
        this.xSeries.forEach((xAxis) => {
          arr.push(map.get(xAxis));
        });
        arrs.push(arr);
      });
      this.mapArr = arrs;
    },
  },
};
</script>
