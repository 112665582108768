//变压器
<template>
  <g class="transformer" v-on:click="$emit('transClick')">
    <rect width="40" height="66" fill="transparent"></rect>
    <circle
      cx="20"
      cy="20"
      r="20"
      stroke="white"
      stroke-width="2"
      fill="none"
    ></circle>
    <circle
      cx="20"
      cy="44"
      r="20"
      stroke="white"
      stroke-width="2"
      fill="none"
    ></circle>
    <triangle transform="translate(31,20),scale(0.3),rotate(180)"></triangle>
    <path
      transform="translate(14,45),scale(0.2)"
      d="M26,26 L0,0 M26,26 L52,0 M26,26 L26,52"
      stroke="white"
      stroke-width="10"
      fill="none"
    ></path>
  </g>
</template>
<script>

import triangle from '@/components/court-circuit-diagram/svgs/utils/triangle.vue';

export default {
  name: 'transformer',
  components: {
    triangle,
  },
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
  },
};
</script>
